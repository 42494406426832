import React, { useState } from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import priceImg from '../../../assets/images/feature/price_img.svg'
import priceImg1 from '../../../assets/images/feature/price_img1.svg'
import priceImg2 from '../../../assets/images/feature/price_img2.svg'
import priceCheck from '../../../assets/images/icons/price-check.png'
import sectionBg10 from '../../../assets/images/banner/section-bg10.png'
import sectionBg11 from '../../../assets/images/banner/section-bg11.png'

const Home4Price = () => {
    let [priceToggle, setPriceToggle] = useState(true)
    return (
        <>
            <section className='section-bg-shape2'>
                <div className="sc-price-style p-z-idex sc-pb-130 sc-md-pb-70">
                    <div className="container">
                        <div className="sc-heading-area sc-mb-40 sc-md-mb-30 text-center">
                            <h2 className="title sc-mb-25">سعر خاص <span className="gradiant-color">لك</span></h2>
                        </div>
                        <div className="pricing-selector-two">
                            <form>
                                <button type="button" className="pricing-monthly-btn">سنوي <span>(35% تخفيض)</span></button>
                                <input type="checkbox" name="pricing-selector" id="pricing-selector" onChange={() => setPriceToggle(!priceToggle)} />
                                <button type="button" className="pricing-yearly-btn">شهري</button>
                            </form>
                        </div>
                        <div className={`${priceToggle ? 'pricing-monthly' : 'pricing-monthly d-none'}`}>
                            <div className="row">
                                <div className="col-lg-44 .col-md-66 sc-md-mb-30">
                                    <Fade bottom delay={100}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">المجانية</h4>
                                                    <div className="sc-price">0 دج<span className="month">   - مدى الحياة</span></div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                    <li><img src={priceCheck} alt="Check" /> متجر الكتروني احترافي</li>
                                                    <li><img src={priceCheck} alt="Check" /> دومين فرعي مجاني</li>
                                                    <li><img src={priceCheck} alt="Check" /> منتجات غير محدودة </li>
                                                    <li><img src={priceCheck} alt="Check" /> اقتطاع على كل طلب 10دج ( 30 طلب مجاني ) </li>
                                                    <li><img src={priceCheck} alt="Check" /> 1 فايسبوك بيكسل </li>
                                                    <li><img src={priceCheck} alt="Check" /> 1 تيك توك بيكسل </li>
                                                    <li><img src={priceCheck} alt="Check" /> بدون تخصيص واجهة المتجر CSS </li>
                                                    <li><img src={priceCheck} alt="Check" /> مع حقوق النشر فوورويب</li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-btn" onClick={() => { window.open( "https://admin.foorweb.store/login") }}><span>شراء</span></Link>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-lg-44 .col-md-66 sc-md-mb-30">
                                    <Fade bottom delay={200}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg1} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">المتطورة</h4>
                                                    <div className="sc-price">2900دج<span className="month">   - شهر</span></div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                    <li><img src={priceCheck} alt="Check" /> متجر إلكتروني احترافي</li>
                                                    <li><img src={priceCheck} alt="Check" /> دومين إحترافي (1)</li>
                                                    <li><img src={priceCheck} alt="Check" /> منتجات غير محدودة </li>
                                                    <li><img src={priceCheck} alt="Check" /> طلبات غير محدودة </li>
                                                    <li><img src={priceCheck} alt="Check" />طلبات متروكة 10دج</li>
                                                    <li><img src={priceCheck} alt="Check" /> 2 فايسبوك بيكسل </li>
                                                    <li><img src={priceCheck} alt="Check" /> 2 تيك توك بيكسل </li>
                                                    <li><img src={priceCheck} alt="Check" /> بدون تخصيص واجهة المتجر CSS </li>
                                                    <li><img src={priceCheck} alt="Check" />  بدون حقوق النشر فوورويب </li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-active" onClick={() => { window.open( "https://admin.foorweb.store/login") }}><span>شراء</span></Link>
                                            </div>
                                            <div className="price_sale">
                                                <span className="popular">الأكثر طلبا</span>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-lg-44 .col-md-66">
                                    <Fade bottom delay={300}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg2} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">الأسطورية</h4>
                                                    <div className="sc-price">5900دج<span className="month">   - شهر</span></div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                    <li><img src={priceCheck} alt="Check" />متجر الكتروني احترافي</li>
                                                    <li><img src={priceCheck} alt="Check" /> دومين احترافي (غير محدود)</li>
                                                    <li><img src={priceCheck} alt="Check" /> منتجات غير محدودة</li>
                                                    <li><img src={priceCheck} alt="Check" /> طلبات غير محدودة</li>
                                                    <li><img src={priceCheck} alt="Check" /> طلبات متروكة غير محدودة</li>
                                                    <li><img src={priceCheck} alt="Check" />  فايسبوك بيكسل غير محدود </li>
                                                    <li><img src={priceCheck} alt="Check" />  تيك توك بيكسل غير محدود </li>
                                                    <li><img src={priceCheck} alt="Check" /> تخصيص واجهة المتجر CSS </li>
                                                    <li><img src={priceCheck} alt="Check" /> بدون حقوق النشر فوورويب</li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-btn" onClick={() => { window.open( "https://admin.foorweb.store/login") }}><span>شراء</span></Link>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                        <div className={`${priceToggle ? 'pricing-yearly d-none' : 'pricing-yearly'}`}>
                            <div className="row">
                                <div className="col-lg-44 .col-md-66 sc-md-mb-30">
                                    <Fade bottom delay={100}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">المجانية</h4>
                                                    <div className="sc-price">0 دج<span className="month"> - مدى الحياة</span></div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                <li><img src={priceCheck} alt="Check" /> متجر الكتروني احترافي</li>
                                                    <li><img src={priceCheck} alt="Check" /> دومين فرعي مجاني</li>
                                                    <li><img src={priceCheck} alt="Check" /> منتجات غير محدودة </li>
                                                    <li><img src={priceCheck} alt="Check" /> اقتطاع على كل طلب 10دج ( 30 طلب مجاني ) </li>
                                                    <li><img src={priceCheck} alt="Check" /> 1 فايسبوك بيكسل </li>
                                                    <li><img src={priceCheck} alt="Check" /> 1 تيك توك بيكسل </li>
                                                    <li><img src={priceCheck} alt="Check" /> بدون تخصيص واجهة المتجر CSS </li>
                                                    <li><img src={priceCheck} alt="Check" /> مع حقوق النشر فوورويب</li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-btn" onClick={() => { window.open( "https://admin.foorweb.store/login") }}><span>شراء</span></Link>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-lg-44 .col-md-66 sc-md-mb-30">
                                    <Fade bottom delay={200}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg1} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">المتطورة</h4>
                                                    <div className="sc-price">27500دج<span className="month"> - سنة</span></div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                <li><img src={priceCheck} alt="Check" /> متجر إلكتروني احترافي</li>
                                                <li><img src={priceCheck} alt="Check" /> دومين إحترافي (1)</li>
                                                <li><img src={priceCheck} alt="Check" /> منتجات غير محدودة </li>
                                                    <li><img src={priceCheck} alt="Check" /> طلبات غير محدودة </li>
                                                    <li><img src={priceCheck} alt="Check" />طلبات متروكة 10دج</li>
                                                    <li><img src={priceCheck} alt="Check" /> 2 فايسبوك بيكسل </li>
                                                    <li><img src={priceCheck} alt="Check" /> 2 تيك توك بيكسل </li>
                                                    <li><img src={priceCheck} alt="Check" /> بدون تخصيص واجهة المتجر CSS </li>
                                                    <li><img src={priceCheck} alt="Check" />  بدون حقوق النشر فوورويب </li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-active" onClick={() => { window.open( "https://admin.foorweb.store/login") }}><span>شراء</span></Link>
                                            </div>
                                            <div className="price_sale">
                                                <span className="popular">أكثر طلبا</span>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-lg-44 .col-md-66">
                                    <Fade bottom delay={300}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">الأسطورية</h4>
                                                    <div className="sc-price">59000دج<span className="month"> - سنة</span></div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                <li><img src={priceCheck} alt="Check" />متجر الكتروني احترافي</li>
                                                    <li><img src={priceCheck} alt="Check" /> دومين احترافي (غير محدود)</li>
                                                    <li><img src={priceCheck} alt="Check" /> منتجات غير محدودة</li>
                                                    <li><img src={priceCheck} alt="Check" /> طلبات غير محدودة</li>
                                                    <li><img src={priceCheck} alt="Check" /> طلبات متروكة غير محدودة</li>
                                                    <li><img src={priceCheck} alt="Check" />  فايسبوك بيكسل غير محدود </li>
                                                    <li><img src={priceCheck} alt="Check" />  تيك توك بيكسل غير محدود </li>
                                                    <li><img src={priceCheck} alt="Check" /> تخصيص واجهة المتجر CSS </li>
                                                    <li><img src={priceCheck} alt="Check" /> بدون حقوق النشر فوورويب</li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-btn" onClick={() => { window.open( "https://admin.foorweb.store/login") }}><span>شراء</span></Link>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sc-section-bg2">
                    <img className="bg-image11" src={sectionBg10} alt="Bg" />
                    <img className="bg-image12" src={sectionBg11} alt="Bg" />
                </div>
            </section>
        </>
    );
};

export default Home4Price;
